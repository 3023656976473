// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
            $element.addClass('active');
        }
        else {
        $element.removeClass('active');
        }
    });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');


(function() {

    var s = {

        menuState: function() {

            $(this).toggleClass("cross");
            $(".main-nav-wrapper").toggleClass("open");

        },

        headerHeight: function() {

            if ($(window).scrollTop() > 0) {

                return $('.main-header').height();
            } else {

                return $('.main-header').height() - 24;
            }
        },

        headerPosition: function() {

            var top = $(window).scrollTop();

            if (top > 300) {

                $(".main-header").addClass("min");
                $(".sub-nav").addClass("min");
            } else {

                $(".main-header").removeClass("min");
                $(".sub-nav").removeClass("min");
            }
        },

        events: function() {
            $(window).scroll(this.headerPosition);
            $("#btnMobile").on("click", this.menuState);
        },

        init: function() {

            this.headerPosition();
            this.events();
        }

    };

    s.init();

})();

// SUBNAV NAVIGATION
$("ul > li.menu-item-has-children > a").click(function (e) {
    e.preventDefault();
    var LiParent =$(this).parent().attr('class');
    if(! LiParent.includes("openSub-menu") ){
        $('ul > li.menu-item-has-children > a').parent().removeClass("openSub-menu");
    }

    $(this).parent().toggleClass("openSub-menu");

});

// -- CLOSE NAV WHEN CLICK ANYWHERE
$(document).on("click", function (event) {
    var $trigger = $(".menu-item-has-children");
    if ($trigger !== event.target && !$trigger.has(event.target).length) {
        $("li.menu-item-has-children").removeClass("openSub-menu");
    }
});

// ANCRE
$('a[href^="#"]').on('click',function (e) {
    e.preventDefault();

    var target = this.hash,
    $target = $(target);

    $('html, body').stop().animate({
        'scrollTop': $target.offset().top
    }, 1200, 'swing', function () {
        window.location.hash = target;
    });
});

// CLOSE NAV
jQuery('#menu-primary-navigation a').click(function(e) {
  console.log("click menu-item a");
  $("#btnMobile").toggleClass("cross");
  $(".main-nav-wrapper").toggleClass("open");
});

// COUT NUMBER ANIMATION
// inViewport jQuery plugin
// http://stackoverflow.com/a/26831113/383904
// Based on Fiddle: https://jsfiddle.net/2v3mq3nh/4/
// From this Answer: http://stackoverflow.com/a/36980280
$(function($, win) {
  $.fn.inViewport = function(cb) {
    return this.each(function(i,el){
      function visPx(){
        var H = $(this).height(),
            r = el.getBoundingClientRect(), t=r.top, b=r.bottom;
        return cb.call(el, Math.max(0, t>0? H-t : (b<H?b:H)));
      } visPx();
      $(win).on("resize scroll", visPx);
    });
  };
}(jQuery, window));

jQuery(function($) { // DOM ready and $ in scope

  $(".count").inViewport(function(px) { // Make use of the `px` argument!!!
    // if element entered V.port ( px>0 ) and
    // if prop initNumAnim flag is not yet set
    //  = Animate numbers
    if(px>0 && !this.initNumAnim) {
      this.initNumAnim = true; // Set flag to true to prevent re-running the same animation
      $(this).prop('Counter',0).animate({
        Counter: $(this).text()
      }, {
        duration: 2000,
        step: function (now) {
          $(this).text(parseFloat(now.toFixed(0)));
        }
      });
    }
  });

});

// SLICK
$(document).ready(function(){

  // Slideshow homepage
  $('.slider-wrapper-home').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    fade: true,
    cssEase: 'linear',
    infinite: true,
  });

  $(document).ready(function(){
    $('.active-slider .wrapper-block').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      cssEase: 'linear',
      infinite: true,
      responsive: [

  {
    breakpoint: 768,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    }
  },]
    });
  });

  $(document).ready(function(){
    $('.team-slider > .content').slick({
      slidesToShow: 5,
      slidesToScroll: 5,
      responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    }
  },]
    });
  });

  // Flexiblen content logo
  $('.repeater-logo-wrapper > .content').slick({
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    cssEase: 'linear',
    infinite: true,
    responsive: [
  {
    breakpoint: 1280,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 4,
    }
},
{
  breakpoint: 768,
  settings: {
    slidesToShow: 2,
    slidesToScroll: 2,
  }
},]
  });
});
